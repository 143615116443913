import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './index.scss';

import { Section, Container, Row, Column } from 'components/atoms';

const isLightColor = color => {
  const hex = color.replace('#', '');
  const fullHex = hex.length < 6 ? hex.repeat(2) : hex;
  const rgb = parseInt(fullHex, 16);
  const r = (rgb >> 16) & 0xff;
  const g = (rgb >> 8) & 0xff;
  const b = (rgb >> 0) & 0xff;
  const brightness = 0.2126 * r + 0.7152 * g + 0.0722 * b;
  return brightness >= 155;
};

const WidgetWorkText = ({ data, className }) => {
  const { content, background_fill, background_color, same_color } = data;

  const backgroundColorStyle = background_fill
    ? {
        backgroundColor: `${background_color}`,
      }
    : {};

  const isTextWhite = background_fill && !isLightColor(background_color);

  return (
    <Section
      className={cn(
        'widget-work-text',
        { 'widget-work-offset-bg_fill': background_fill },
        { 'widget-work-offset-bg_same-color': same_color },
        [className]
      )}
      style={backgroundColorStyle}
    >
      <Container>
        <Row>
          <Column
            lg="8"
            col="12"
            className={cn('offset-lg-2', 'widget-work-text__content', {
              'widget-work-text__content_white': isTextWhite,
            })}
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </Row>
      </Container>
    </Section>
  );
};

WidgetWorkText.defaultProps = {
  className: '',
};

WidgetWorkText.propTypes = {
  data: PropTypes.object.isRequired,
  className: PropTypes.string,
};

export default WidgetWorkText;
